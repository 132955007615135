@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/PPNeueMontreal-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/PPNeueMontreal-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/PPNeueMontreal-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/PPNeueMontreal-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("./assets/fonts/PPNeueMontreal-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Berlingske";
  src: url("./assets/fonts/BerlingskeSerif-Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Berlingske";
  src: url("./assets/fonts/BerlingskeSerif-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    /* --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%; */

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Close Icon */
/* .nextui-c-hdxYb {
  background: #dbdbdb !important;
} */

.embla2 {
  overflow: hidden;
  width: 100%;
}
.embla__container2 {
  display: block;
}
.embla__slide2 {
  flex: 0 0 100%;
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.str-chat__channel-list {
  background-color: #ffffff !important;
  border-right: 1px solid #ebebeb;
  /* width: 300px; */
  width: 33.3%;
}

.channel-preview__container {
  height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-preview__container:hover {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.channel-preview__container.selected {
  background: #f2f2f2;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 12px;
  width: 100%;
}

.channel-preview__content-wrapper-left {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.channel-preview__content-wrapper-right {
  display: flex;
  justify-content: flex-end; /* Updated justify-content property */
  align-items: center;
  width: 20%;
}

.channel-preview__content-wrapper-right-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(186, 0, 0);
  color: white;
}

.channel-preview__content-wrapper-right-circle p {
  margin: 0;
  font-size: 12px;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  height: 18px;
  margin-bottom: 4px;
}

.channel-preview__content-name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__content-message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 16px;
  margin: 0;
  color: #575757;
  height: 16px;
  max-width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding-top: 0 !important;
  padding-right: 0 !important;
}
.str-chat {
  height: 90vh !important;
}

.str-chat__message-simple-name {
  display: none !important;
}

.str-chat__message--me .str-chat__message-text-inner,
.str-chat__message-simple--me .str-chat__message-text-inner {
  background-color: rgba(7, 117, 252, 0.274) !important;
}

.str-chat__avatar--circle {
  display: none !important;
}
.str-chat__message--me > .str-chat__avatar {
  display: none;
}

div[role="region"] {
  overflow: visible;
}

/* CONTEXT: FAQS QUESTION */
h3 {
  font-weight: 400 !important;
}

.swiper {
  width: 20%;
  height: auto;
}

/* CONTEXT: IMAGE FADE IN/OUT */
/* styles.css */

/* Handling the "appear" phase */
.fade-appear,
.fade-enter,
.fade-exit-done {
  opacity: 0;
}

/* Handling the "appear-active" phase */
.fade-appear-active,
.fade-enter-active,
.fade-exit {
  opacity: 1;
}

/* Define transitions */
.fade-appear-active,
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms ease-out;
}
