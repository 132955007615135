/** Swiper styles **/

.swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 300px;

  padding: 0px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86%;
  height: 100%;
  color: #fff;
  position: relative;
  box-sizing: border-box;

  background-color: rgba(0, 0, 0, 1);

  border-radius: 25px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  padding: 24px 24px;

  align-items: flex-start;
  justify-content: center;
}

.swiper-slide-title {
  font-size: 21px;
  font-weight: bold;
  transform-origin: left bottom;
  color: rgba(255, 255, 255, 1);
}

.swiper-slide-image {
  border-radius: 25px;
  position: absolute;
  object-fit: cover;

  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;

  z-index: 0;
}

@media (min-width: 768px) {
  .swiper {
    width: 80%;

    height: 300px;

    padding: 0px 0px;
  }

  .swiper-slide {
    width: 33%;
    height: 100%;
    border-radius: 25px;
  }

  .swiper-slide-content {
    padding: 24px 24px;
  }
  :root {
    --swiper-navigation-top-offset: 50%;
    --swiper-navigation-color: white;
  }
}
